<!-- eslint-disable vue/valid-v-model -->
<template>
  <div>
    <loader
      v-if="Loadmore"
      object="#9658cc"
      color1="#532a76"
      color2="#d3bae9"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="30"
      name="circular"
    />
    <!-- <this-header /> -->
    <div class="bg-white pt-1">
      <div class="container-lott pb-2r">
        <div class="box-search">
          <div class="box-search__title">
            <h1 class="title mb-0">
              ค้นหาเลขเด็ด
            </h1>

            <p class="text-primary">
              งวดประจำวันที่ {{ roundth }}
            </p>
          </div>
          <div class="d-flex justify-content-center">
            <otp-input
              v-if="renderComponent"
              ref="otpInput"
              v-model="input_numlot"
              :digits="6"
            >
              <template #errorMessage>
                There is an error
              </template>
            </otp-input>
          </div>

          <div class="row mt-1 pl-1 pr-1">
            <div class="col-4 p-25">
              <button
                type="button"
                class="btn btn-delete w-100"
                @click="cleartext()"
              >
                ลบตัวเลข
              </button>
            </div>
            <div class="col-8 p-25">
              <button
                type="button"
                class="btn btn-search w-100"
                @click="Search()"
              >
                ค้นหาลอตเตอรี่
                <img
                  src="@/assets/images/newIcon/ic_menu/searchLot.png"
                  class="ml-n50"
                  height="30"
                >
              </button>
            </div>
          </div>
        </div>

        <div class="w-80 mt-2 pl-1 pr-1">
          <swiper
            class="swiper-paginations"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide
              v-for="(data, index) in swiperData"
              :key="index"
            >
              <b-img
                :src="data.img"
                fluid
                style="border-radius: 17px"
              />
            </swiper-slide>
          </swiper>

          <div class="d-flex justify-content-center mt-50">
            <div
              slot="pagination"
              class="swiper-pagination"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white mt-1">
      <div class="container-sm-lot pb-1 pt-1">
        <div class="row">
          <div class="col-3 pl-50">
            <div
              class="text-center cursor-pointer cursor-pointer"
              @click="luckyBox()"
            >
              <div class="box-img">
                <img
                  src="@/assets/images/newIcon/gift-box.gif"
                  alt="กล่องสุ่มเลข"
                  height="100"
                >
              </div>
              <p class="mb-0 text-primary">
                กล่องสุ่มเลข
              </p>
            </div>
          </div>
          <div class="col-3 pr-50 pl-50">
            <div
              class="text-center cursor-pointer"
              @click="luckyWheel()"
            >
              <!-- <img
                src="@/assets/images/newIcon/roulette-wheel.gif"
                alt="กงล้อรับโชค"
                height="70"
              > -->

              <div class="box-img-2">
                <img
                  src="@/assets/images/newIcon/roulette-wheel.gif"
                  alt="กล่องสุ่มเลข"
                  height="55"
                >
              </div>
              <p class="mb-0 text-primary">
                กงล้อรับโชค
              </p>
            </div>
          </div>

          <div class="col-3 pl-50 pr-50">
            <div
              class="text-center cursor-pointer"
              @click="inviteFriend()"
            >
              <img
                src="@/assets/images/newIcon/iv-friend.png"
                alt="แนะนำเพื่อน"
                height="70"
              >
              <p class="mb-0 text-primary">
                แนะนำเพื่อน
              </p>
            </div>
          </div>
          <div class="col-3 pl-50">
            <div
              class="text-center"
              style="opacity: 0.4;"
            >
              <img
                src="@/assets/images/newIcon/hide.png"
                alt="ทำนายฝัน"
                style="filter: grayscale(50%)"
                height="70"
              >
              <p class="mb-0 text-primary">
                ทำนายฝัน
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white mt-1 pl-1 pr-1">
      <div class="w-80 pb-1 pt-1">
        <div
          class="txt-head-news d-flex justify-content-between align-items-center"
        >
          <h3 class="mb-0 text-primary">
            ข่าวสาร
          </h3>

          <span
            class="text-muted cursor-pointer"
            @click="$router.push({ name: 'news-all' })"
          >
            ดูทั้งหมด
          </span>
        </div>

        <div
          v-for="(item, index) in NewsData"
          :key="index"
          class="txt-head-news d-flex justify-content-start mt-1 mb-2 cursor-pointer"
          @click="viewDetail(item)"
        >
          <div class="img-news mr-1 w-25">
            <img
              :src="item.img"
              alt=""
            >
          </div>

          <div class="news-detail w-75">
            <div class="txt-clip-title">
              <p class="title-top text-primary mb-0">
                {{ item.title }}
              </p>
            </div>

            <div class="txt-clip-detail mb-1">
              <!-- eslint-disable vue/no-v-html -->
              <p
                class="detail-new text-muted"
                v-html="item.detail"
              />
            </div>

            <small class="text-muted">
              <i class="fal fa-calendar-alt" /> {{ showdate(item.created_at) }}
            </small>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white mt-1">
      <div class="w-80 pb-1 pt-1">
        <div class="row">
          <div class="col-lg-6">
            <img
              src="@/assets/images/banner/Banner2.png"
              alt="Banner2"
              class="img-fluid cursor-pointer"
              @click="buyLotto()"
            >
          </div>

          <div class="col-lg-6">
            <img
              src="@/assets/images/banner/Banner3.png"
              alt="Banner2"
              class="img-fluid cursor-pointer"
              @click="luckyBox()"
            >
          </div>

          <div class="col-lg-6">
            <img
              src="@/assets/images/banner/Banner4.png"
              alt="Banner2"
              class="img-fluid cursor-pointer"
              @click="myPoint()"
            >
          </div>

          <div class="col-lg-6">
            <img
              src="@/assets/images/banner/Banner5.png"
              alt="Banner2"
              class="img-fluid cursor-pointer"
              @click="luckyWheel()"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white mt-1">
      <div class="pb-1 pt-1 lotto-cg">
        <div>
          <div class="text-center">
            <h3 class="mb-0 text-primary">
              เลขฮิต เลขดังมาแรง!
            </h3>
          </div>
          <div class="text-center">
            <h4 class="mb-0 my-1 text-primary">
              เลขท้าย 2 ตัว
            </h4>
          </div>

          <div class="row">
            <div
              v-for="(src, index) in savedImages2b"
              id="disabled"
              :key="index"
              class="col-md-3 col-6 mb-md-2 mt-md-2 mb-50 mt-50 lotto-item p-50"
            >
              <div class="-cart">
                <div v-if="addedIds.includes(src.lotteryData._id)">
                  <div
                    style="
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      background-color: black;
                      opacity: 0.5;
                      z-index: 9;
                    "
                  />
                  <img
                    src="/images/box/added.png"
                    alt="cart-add"
                    class="ic-cart"
                  >
                </div>

                <div class="sc-keVmGr csMRRn lotto-item">
                  <div class="img-lott d-md-block d-none">
                    <img
                      :key="index"
                      :src="src.dataURL"
                      width="100%"
                    >
                  </div>

                  <div class="img-lott d-md-none d-block">
                    <img
                      :key="index"
                      width="100%"
                      :src="src.dataURL"
                    >
                  </div>
                </div>
                <div class="d-flex">
                  <b-form-select
                    v-model="src.lotteryData.status"
                    :options="filteredOptions(src.lotteryData.quantity_now)"
                    class="mr-50"
                    style="width: 35%"
                  />
                  <button
                    class="btn-add-cart"
                    style="width: 65%"
                    @click="addCart(src.lotteryData, src.lotteryData.status)"
                  >
                    <i class="fal fa-cart-plus mr-50" /> เพิ่มลงตะกร้า
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="text-center">
            <h4 class="mb-0 my-1 text-primary">
              เลขท้าย 3 ตัว
            </h4>
          </div>

          <div class="row">
            <div
              v-for="(src, index) in savedImages3b"
              id="disabled"
              :key="index"
              class="col-md-3 col-6 mb-md-2 mt-md-2 mb-50 mt-50 lotto-item p-50"
            >
              <div class="-cart">
                <div v-if="addedIds.includes(src.lotteryData._id)">
                  <div
                    style="
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      background-color: black;
                      opacity: 0.5;
                      z-index: 9;
                    "
                  />
                  <img
                    src="/images/box/added.png"
                    alt="cart-add"
                    class="ic-cart"
                  >
                </div>

                <div class="sc-keVmGr csMRRn lotto-item">
                  <div class="img-lott d-md-block d-none">
                    <img
                      :key="index"
                      :src="src.dataURL"
                      width="100%"
                    >
                  </div>

                  <div class="img-lott d-md-none d-block">
                    <img
                      :key="index"
                      width="100%"
                      :src="src.dataURL"
                    >
                  </div>
                </div>
                <div class="d-flex">
                  <b-form-select
                    v-model="src.lotteryData.status"
                    :options="filteredOptions(src.lotteryData.quantity_now)"
                    class="mr-50"
                    style="width: 35%"
                  />
                  <button
                    class="btn-add-cart"
                    style="width: 65%"
                    @click="addCart(src.lotteryData, src.lotteryData.status)"
                  >
                    <i class="fal fa-cart-plus mr-50" /> เพิ่มลงตะกร้า
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-80 my-1">
      <div class="bank-seo">
        <div
          v-for="(item, index) in imgBank"
          :key="index"
          class="img-bank-all"
        >
          <img
            :src="item.img"
            alt="bank"
          >
        </div>
      </div>
    </div>

    <div class="copy-right text-center">
      <p class="text-primary small">
        Copyright 2022 @Suwarnaphomi Lottery | All Rights Reserved
      </p>
    </div>

    <br><br><br>

    <ThisFooter :added-ids="MyCarts" />

    <b-modal
      ref="my-welcome"
      hide-footer
      hide-header
      centered
    >
      <div class="text-center">
        <div class="closed">
          <i
            class="far fa-times cursor-pointer icon-close"
            @click="$refs['my-welcome'].hide()"
          />
        </div>

        <h4 class="mb-0 text-primary mt-2">
          กรุณาเข้าสู่ระบบเพื่อทำการสั่งซื้อ
        </h4>

        <button
          class="btn-gray-txt p-75 w-75 my-2"
          @click="$refs['my-welcome'].hide(), $router.push({ name: 'login' })"
        >
          ไปที่หน้าเข้าสู่ระบบ
        </button>
      </div>
    </b-modal>

    <!-- icon contact line gif -->
    <div
      class="icon-contact"
      :style="`display: ${lineContact ? 'block' : 'none'}`"
    >
      <div class="box-detail-line">
        <i
          class="fal fa-times ic-close"
          @click="lineContact = false"
        />
        <a
          :href="contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/suvan/bubble-chat.gif"
            alt="line"
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { BModal, BImg, BFormSelect } from 'bootstrap-vue'
import ThisFooter from './components/ThisFooter.vue'
import OtpInput from './components/OtpInput.vue'
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
import 'vue-loading-overlay/dist/vue-loading.css'
// eslint-disable-next-line import/order
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'Home',
  metaInfo: {
    title:
      'สลากสุวรรณภูมิ | ซื้อสลากได้ที่นี่! สลากกินแบ่งรัฐบาลออนไลน์ มากกว่านรูปแบบออนไลน์ ได้ที่นี่',
    meta: [
      {
        name: 'สลากสุวรรณภูมิ | ซื้อสลากได้ที่นี่! สลากกินแบ่งรัฐบาลออนไลน์ มากกว่านรูปแบบออนไลน์ ได้ที่นี่',
        content:
          'สลากสุวรรณภูมิ จำหน่ายสลากกินแบ่งรัฐบาลออนไลน์ แบบถูกกฎหมาย ในรูปแบบออนไลน์ ได้ที่นี่ ที่เดียวมีเลขให้เลือก มากกว่า 3000000 เลข ติดต่อแอดมิน จำหน่ายตลอด 24 ชม.',
      },
    ],
  },
  components: {
    // ThisHeader,
    BFormSelect,
    ThisFooter,
    OtpInput,
    // SkeletonLoaderVu,
    // LoadingHome,
    BModal,
    BImg,
    Swiper,
    SwiperSlide,
    // BPagination,
  },
  data() {
    return {
      amountLotto: [
        { text: 'จำนวน', value: 0 },
        { text: '1 ใบ', value: 1 },
        { text: '2 ใบ', value: 2 },
        { text: '3 ใบ', value: 3 },
        { text: '4 ใบ', value: 4 },
        { text: '5 ใบ', value: 5 },
        { text: '6 ใบ', value: 6 },
        { text: '7 ใบ', value: 7 },
        { text: '8 ใบ', value: 8 },
        { text: '9 ใบ', value: 9 },
        { text: '10 ใบ', value: 10 },
      ],
      imgLotto: '/lotto/lotto.png',
      contact: null,
      lineContact: true,
      addedIds: [],
      MyCarts: [],
      imgBank: [
        { img: '/bank/002.jpg' },
        { img: '/bank/003.jpg' },
        { img: '/bank/004.jpg' },
        { img: '/bank/007.jpg' },
        { img: '/bank/011.jpg' },
        { img: '/bank/020.jpg' },
        { img: '/bank/023.jpg' },
        { img: '/bank/027.jpg' },
        { img: '/bank/031.jpg' },
        { img: '/bank/032.jpg' },
        { img: '/bank/034.jpg' },
        { img: '/bank/035.jpg' },
        { img: '/bank/037.jpg' },
        { img: '/bank/039.jpg' },
        { img: '/bank/045.jpg' },
        { img: '/bank/048.jpg' },
        { img: '/bank/071.jpg' },
        { img: '/bank/081.jpg' },
        { img: '/bank/088.jpg' },
        { img: '/bank/089.jpg' },
        { img: '/bank/090.jpg' },
      ],
      rewards: [
        { name: '7', percentage: 0 },
        { name: '6', percentage: 0 },
        { name: '5', percentage: 0 },
        { name: '4', percentage: 0 },
        { name: '3', percentage: 2 },
        { name: '2', percentage: 8 },
        { name: '1', percentage: 90 },
      ],
      reward: null,
      roundth: '',
      balance: 0,
      Empyty: '',
      Loadmore: false,
      isLoadings: false,
      fullPage: true,
      numberInput: [],
      textOutput: [],
      renderComponent: true,
      /* eslint-disable global-require */
      // swiperData: [
      //   { img: require('@/assets/banner/Banner1.png') },
      //   { img: require('@/assets/banner/Banner2.png') },
      //   { img: require('@/assets/banner/Banner3.png') },
      //   { img: require('@/assets/banner/Banner4.png') },
      // ],
      /* eslint-disable global-require */

      swiperOptions: {
        spaceBetween: 30,
        effect: 'fade',
        loop: true,
        centeredSlides: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      textConfig: {
        Number: {
          x: 164,
          y: 0,
          fontSize: 18,
          color: '#000000',
          fontFamily: 'suvarnnabhumiNumber',
        },
        Text: {
          x: 164,
          y: 1,
          fontSize: 8,
          color: '#000000',
          fontFamily: 'suvarnnabhumiNumber',
        },
        'Date (TH)': {
          x: 50,
          y: 80,
          fontSize: 12,
          color: '#494949',
        },
        'Date (EN)': {
          x: 50,
          y: 120,
          fontSize: 11,
          color: '#ED5C85',
        },
        Round: {
          x: 50,
          y: 200,
          fontSize: 12,
          color: '#ED5C85',
        },
        Set: {
          x: 50,
          y: 250,
          fontSize: 12,
          color: '#000000',
        },
        Price: {
          x: 50,
          y: 300,
          fontSize: 18,
          color: '#ED5C85',
        },
      },
      userData: JSON.parse(localStorage.getItem('userData')),
      lotteryset3b: [],
      lotteryset2b: [],
      savedImages3b: [],
      savedImages2b: [],
      NewsData: [],
      img: '',
    }
  },
  created() {
    this.$http.get('lotto-suvarn/GetRound').then(ress => {
      localStorage.setItem('imgLotto', JSON.stringify(ress.data.img))
      // console.log(this.img)
    })

    const imgLotto = JSON.parse(localStorage.getItem('imgLotto'))

    if (imgLotto) {
      this.img = imgLotto
    }

    this.$http.get('/GetContact').then(res => {
      this.contact = res.data.linecontact
      // console.log(this.contact)
    })

    const storedItems = localStorage.getItem('cartItems')
    if (storedItems) {
      this.cartItems = JSON.parse(storedItems)
    }
    const storedItemsNew = localStorage.getItem('NewsData')
    if (storedItemsNew) {
      this.NewsData = JSON.parse(storedItemsNew)
    }

    if (this.cartItems.length > 0) {
      this.startCountdown()
    }

    if (this.userData) {
      this.$router.push({ name: 'lottery' })
    }
  },
  async mounted() {
    localStorage.removeItem('otp')
    localStorage.removeItem('type')
    // await this.GetRound()
    await this.getBanner()
    this.GetNew()
    this.generateImages3b()
    this.generateImages2b()
    this.$nextTick(() => {
      this.$refs.otpInput.$el.querySelector('input').blur()
    })
  },
  methods: {
    filteredOptions(count) {
      return this.amountLotto.slice(0, count)
    },
    async getBanner() {
      try {
        const res = await this.$http.get('lotto-suvarn/Banner')
        this.swiperData = res.data
        // console.log(this.swiperData)
      } catch (error) {
        console.log(error)
      }
    },
    // GetRound() {
    //   this.$http.get('lotto-suvarn/GetRound').then(ress => {
    //     // console.log(ress.data)
    //     this.img = ress.data.img
    //   })
    // },
    GetNew() {
      this.$http.get('lotto-suvarn/NewsIndexHome').then(ress => {
        // console.log(ress.data)
        this.NewsData = ress.data
        localStorage.setItem('NewsData', JSON.stringify(ress.data))
      })
    },
    showdate(date) {
      const date1 = new Date(date)
      const dd = String(date1.getDate()).padStart(2, '0')
      const mm = String(date1.getMonth() + 1).padStart(2, '0') // January is 0!
      const yyyy = date1.getFullYear()
      return `${dd}/${mm}/${yyyy}`
    },
    buyLotto() {
      if (this.userData) {
        this.$router.push({ name: 'lottery' })
      } else {
        this.$refs['my-welcome'].show()
      }
    },
    myPoint() {
      if (this.userData) {
        this.$router.push({ name: 'point' })
      } else {
        this.$refs['my-welcome'].show()
      }
    },
    luckyBox() {
      if (this.userData) {
        this.$router.push({ name: 'lucky-box' })
      } else {
        this.$refs['my-welcome'].show()
      }
    },
    luckyWheel() {
      if (this.userData) {
        this.$router.push({ name: 'lucky-wheel' })
      } else {
        this.$refs['my-welcome'].show()
      }
    },
    inviteFriend() {
      if (this.userData) {
        this.$router.push({ name: 'invite-friend' })
      } else {
        this.$refs['my-welcome'].show()
      }
    },
    viewDetail(item) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'news', params: { id: item._id } })
      localStorage.setItem('ViewNews', JSON.stringify(item))
    },
    openLuckyBox() {
      const randomNum = Math.random() * 100 // Generate a random number between 0 and 100
      let cumulativePercentage = 0

      // eslint-disable-next-line no-restricted-syntax
      for (const reward of this.rewards) {
        cumulativePercentage += reward.percentage
        if (randomNum < cumulativePercentage) {
          this.reward = reward
          break
        }
      }
    },
    getBalance() {
      this.$http.get('balance/getbalance').then(ress => {
        // console.log(ress.data)
        this.balance = ress.data.balance
        localStorage.setItem('balance', JSON.stringify(ress.data.balance))
      })
    },
    showNew() {
      this.currentPage += 1
      this.totalRows += 1
      this.perPage += 15
      this.generateImages()
    },
    addCart(lotto, count) {
      if (count <= 0) {
        this.SwalError('กรุณาเลือกจำนวนใบที่ต้องการซื้อ')
      } else if (localStorage.getItem('userData')) {
        // eslint-disable-next-line no-underscore-dangle
        this.addedIds.push(lotto._id)

        const addedArray = this.addedIds.map(id => ({ _id: id }))
        localStorage.setItem('added', JSON.stringify(addedArray))
        // console.log(this.added)
        const type = localStorage.getItem('type')

        // console.log(type)
        if (type === '"num6"') {
          // console.log('เข้า')
          localStorage.removeItem('otp')
          localStorage.removeItem('type')
        }
        const Obj = {
          // eslint-disable-next-line no-underscore-dangle
          lotto_id: lotto._id,
          count,
        }
        this.$http
          .post('/lotto-suvarn/AddToCart', Obj)
          .then(() => {
            // this.savedImages = []
            // eslint-disable-next-line no-param-reassign
            lotto.lottonumber = lotto.num6
            // eslint-disable-next-line no-param-reassign
            lotto.updated_at = new Date()
            const s = JSON.parse(localStorage.getItem('MyCart'))
            s.push(lotto)
            localStorage.setItem('MyCart', JSON.stringify(s))
            this.MyCarts = JSON.parse(localStorage.getItem('MyCart'))
            // this.generateImages()
            // this.Success('เพิ่มสลากลงตะกร้าสำเร็จ')
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        // this.SwalError('กรุณา เข้าสู่ระบบก่อนทำรายการ')
        this.$refs['my-welcome'].show()
      }
    },
    convertNumberToText(number) {
      switch (number) {
        case 1:
          return 'ONE'
        case 2:
          return 'TWO'
        case 3:
          return 'THR'
        case 4:
          return 'FOR'
        case 5:
          return 'FIV'
        case 6:
          return 'SIX'
        case 7:
          return 'SEV'
        case 8:
          return 'EIG'
        case 9:
          return 'NIN'
        case 0:
          return 'ZER'
        default:
          return 'EMT'
      }
    },
    cleartext() {
      this.input_numlot = ['', '', '', '', '', '']
      localStorage.removeItem('otp')
      localStorage.removeItem('type')
      // OtpInput Component
      this.renderComponent = false
      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true
      })
      this.getLottery()
    },
    generateImages() {
      this.Loadmore = true
      this.isLoading = true
      const Obj = {
        page: this.currentPage,
        lottotype: JSON.parse(localStorage.getItem('type')),
        lottonumber: localStorage.getItem('otp'),
      }
      // console.log(Obj)
      this.$http
        .post('/lotto-suvarn/list', Obj)
        .then(response => {
          this.lotteryset = response.data.lottery
          this.totalRows = response.data.total
          this.roundth = response.data.lottery[0].roundth
          this.lotteryset.forEach(async lotteryData => {
            // eslint-disable-next-line no-param-reassign
            lotteryData.lottonumber = await Array.from(
              lotteryData.lottonumber,
            ).map(Number)
            const dataURL = await this.drawCanvas(lotteryData)
            this.savedImages.push(dataURL)
            this.isLoading = false
          })
          this.Loadmore = false
        })
        .catch(error => {
          // console.log(error)
          this.isLoading = false
          this.Loadmore = false
          this.Empyty = error.response.data.message
        })
    },
    generateImages3b() {
      this.Loadmore = true
      this.isLoading = true
      const type = JSON.parse(localStorage.getItem('type'))
      const num = localStorage.getItem('otp')
      const Obj = {
        page: this.currentPage,
        lottotype: type,
        lottonumber: num,
      }
      this.$http
        .post('/lotto-suvarn/luckylist/3b', Obj)
        .then(response => {
          if (response.data.lottery.length > 0) {
            this.lotteryset3b = response.data.lottery
            this.roundth = response.data.lottery[0].roundth
            this.totalRows = response.data.total
            this.lotteryset3b.forEach(async lotteryData => {
              // eslint-disable-next-line no-param-reassign
              lotteryData.lottonumber = await Array.from(
                lotteryData.lottonumber,
              ).map(Number)
              const dataURL = await this.drawCanvas(lotteryData)
              this.savedImages3b.push({ dataURL, lotteryData })
              this.isLoading = false
            })
          }
          this.Loadmore = false
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
          this.Loadmore = false
        })
    },
    generateImages2b() {
      this.Loadmore = true
      this.isLoading = true
      const Obj = {
        page: 1,
      }
      this.$http
        .post('/lotto-suvarn/luckylist/2b', Obj)
        .then(response => {
          if (response.data.lottery.length > 0) {
            this.lotteryset2b = response.data.lottery
            // this.roundth = response.data.lottery[0].roundth
            // this.totalRows = response.data.total
            this.lotteryset2b.forEach(async lotteryData => {
              // eslint-disable-next-line no-param-reassign
              lotteryData.lottonumber = await Array.from(
                lotteryData.lottonumber,
              ).map(Number)
              const dataURL = await this.drawCanvas(lotteryData)
              this.savedImages2b.push({ dataURL, lotteryData })
              this.isLoading = false
            })
          }
          // console.log(this.savedImages2b)
          this.Loadmore = false
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
          this.Loadmore = false
        })
    },
    async drawCanvas(lotteryData) {
      const canvas = document.createElement('canvas')
      canvas.width = 299
      canvas.height = 149.72
      const ctx = canvas.getContext('2d')

      const img = new Image()
      img.src = this.img
      // eslint-disable-next-line no-return-assign
      await new Promise(resolve => (img.onload = resolve))
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

      // const rowSpacing = 50
      const colSpacing = 16.5

      const startX = 159
      const startY = 28

      const startX2 = 159
      const startY2 = 36

      lotteryData.lottonumber.forEach((num, index) => {
        this.addText(
          ctx,
          'Number',
          num,
          false,
          startX + index * colSpacing,
          startY,
        )
      })

      lotteryData.lottonumber.forEach((num, index) => {
        const numText = this.convertNumberToText(num) // Convert the number to text
        this.addText(
          ctx,
          'Text',
          numText,
          false,
          startX2 + index * colSpacing,
          startY2,
        )
      })
      // this.addText(
      //   ctx,
      //   'Date (TH)',
      //   lotteryData.roundth,
      //   false,
      //   startX + -0.25 * colSpacing,
      //   startY + 0.6 * rowSpacing,
      //   '',
      //   'bold',
      //   'Propmt',
      // )
      // this.addText(
      //   ctx,
      //   'Date (EN)',
      //   lotteryData.roundEng,
      //   false,
      //   startX + 0.8 * colSpacing,
      //   startY + 0.9 * rowSpacing,
      // )
      // this.addText(
      //   ctx,
      //   'Round',
      //   lotteryData.series_no,
      //   false,
      //   startX + 1.05 * colSpacing,
      //   startY + 1.5 * rowSpacing,
      //   '',
      //   'bold',
      // )
      // this.addText(
      //   ctx,
      //   'Set',
      //   lotteryData.number_set,
      //   false,
      //   startX + 4 * colSpacing,
      //   startY + 1.5 * rowSpacing,
      //   'italic',
      //   'bold',
      // )
      // this.addText(
      //   ctx,
      //   'Price',
      //   '80',
      //   false,
      //   startX + -1.5 * colSpacing,
      //   startY + 1.9 * rowSpacing,
      //   '',
      //   'bold',
      // )

      const dataURL = canvas.toDataURL()
      return dataURL
    },
    addText(
      ctx,
      label,
      text,
      includeLabel = true,
      x,
      y,
      fontStyle = 'normal',
      fontWeight = 'normal',
    ) {
      const config = this.textConfig[label]
      // console.log(config)
      // eslint-disable-next-line no-param-reassign
      ctx.fillStyle = config.color
      // eslint-disable-next-line no-param-reassign
      ctx.font = `${fontStyle} ${fontWeight} ${config.fontSize}px ${config.fontFamily}`
      ctx.fillText(includeLabel ? `${label}: ${text}` : `${text}`, x, y)
    },
    Search() {
      if (this.userData) {
        // ไปหน้า lottery
        this.$router.push({ name: 'lottery' })
      } else {
        // login
        this.$refs['my-welcome'].show()
      }
      // this.savedImages = []
      // this.generateImages()
    },
    clearSearch() {
      this.otpData = ['', '', '', '', '', '']
    },
    // addCart(item) {
    //   this.cartItems.push(item)
    //   localStorage.setItem('cartItems', JSON.stringify(this.cartItems))
    //   },
    // addCart() {
    //   this.$refs['my-welcome'].show()
    // },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ลงตะกร้าสำเร็จ</h3>',
        text: mes,
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        timer: 2000,
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ขออภัยค่ะ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    startCountdown() {
      this.countdownInterval = setInterval(() => {
        this.countdownTime -= 1
        if (this.countdownTime === 0) {
          clearInterval(this.countdownInterval)
        }
      }, 1000)
    },
    beforeDestroy() {
      clearInterval(this.countdownInterval)
    },
  },
}
</script>

<style scoped>
/* @font-face {
  font-family: suvarnnabhumiNumber !important;
  src: url(/font/Suvarnnabhumi_number-Regular.ttf) format('truetype') !important;
} */
.icon-contact {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

.icon-contact .box-detail-line {
  position: relative;
}
.icon-contact .box-detail-line i {
  position: absolute;
  top: 0;
  right: 0;
  color: rgba(10, 10, 10, 0.123);
  font-size: 15px;
  cursor: pointer;
  z-index: 999;
}
.icon-contact .box-detail-line img {
  height: 70px;
}

@media screen and (max-width: 765px) {
  .icon-contact {
    top: 55%;
    right: 10px;
  }
  .icon-contact .box-detail-line img {
  height: 70px;
}
}
.text2 {
  font-size: 1.5em;
  font-weight: 900;
  background-image: linear-gradient(
    to left,
    #f2cc8f 0.23%,
    #fffdf6 49.3%,
    #ab925d 99.84%
  );
  -webkit-background-clip: text;
  color: transparent;
  display: inline;
}
.custom-select {
    padding: 0.438rem 2rem 0.5rem 0.6rem !important;
}
</style>
